import styled from 'styled-components'

export const CardInfoStyle = styled.div`
    width: 30vw;
    height: 40vh;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1000;
    border-radius: 10px;
    padding: 20px;

    p {
        font-size: 16px;
        font-weight: bold;
    }

    h1 {
        font-size: 20px;
        font-weight: bold;
    }

    span {
        font-weight: normal;
    }

    @media (max-width: 1200px) {
        width: 40vw;
    }
    @media (max-width: 900px) {
        width: 60vw;
    }
    @media (max-width: 650px) {
        width: 80vw;
        height: 50vh;
    }
`

export const CloseCardInfo = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
`

export const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    // background-color: blue;
    padding-top: 20px;
`

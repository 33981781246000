import CardInfo from "components/CardInfo";
import { IconTippy, InfoIcon, InfoIconWrapper, LockIcon, LockIconWrapper, LockOpenIcon, TooltipWrapper, UnauthorizedCard } from "./styled";
import { useEffect, useRef, useState } from "react";

const UnauthorizedCardComponent = ({name, fileUrl, reportCardId, data_source, report_description, responsible_id}) => {

  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalInfoOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    const handleKeyDown = (event) => {
      if (modalInfoOpen && event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalInfoOpen]);

  const handleInfo = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setModalInfoOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalInfoOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
    <UnauthorizedCard>
        {(report_description || data_source || responsible_id) && (
          <InfoIconWrapper onClick={handleInfo}>
            <IconTippy content="Informações do relatório" placement="top" arrow={true}>
              <TooltipWrapper>
              <InfoIcon />
            </TooltipWrapper>
          </IconTippy>
        </InfoIconWrapper>
        )}
        <LockIconWrapper>
        <IconTippy 
        // content="Solicitar acesso" 
        content="Acesso bloqueado"
        placement="top" arrow={true}>
            <TooltipWrapper>
              <LockIcon />
              <LockOpenIcon />
            </TooltipWrapper>
          </IconTippy>
        </LockIconWrapper>
        <img src={fileUrl ? `${process.env.REACT_APP_URL_API}/${fileUrl}` : '/standardCard.png'} alt="analyticdi" />
        <p style={{ textAlign: 'center' }} data-content={name}>{name}</p>
    </UnauthorizedCard>

    {modalInfoOpen && (
        <>
          <div style={{ 
              position: 'fixed', 
              top: 0, 
              left: 0, 
              right: 0, 
              bottom: 0, 
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999
          }} onClick={closeModal} />
          <div ref={modalRef} style={{ zIndex: 1000 }}>
            <CardInfo 
            closeModal={closeModal}
            name={name}
            fileUrl={fileUrl}
            reportCardId={reportCardId}
            data_source={data_source}
            report_description={report_description}
            responsible_id={responsible_id}
            />
          </div>
        </>
      )}
    </>
  )
}

export default UnauthorizedCardComponent;

import styled from 'styled-components';
import { FaClock, FaStar, FaThLarge, FaTh } from 'react-icons/fa';

export const FaClockIcon = styled(FaClock)`
  font-size: 14px;

   @media(max-width: 1050px) {
    font-size: 18px;
  }
`;

export const FaStarIcon = styled(FaStar)`
  font-size: 14px;
  
   @media(max-width: 1050px) {
    font-size: 18px;
  }
`;

export const FaThLargeIcon = styled(FaThLarge)`
  font-size: 14px;
  
   @media(max-width: 1050px) {
    font-size: 18px;
  }
`;

export const FaThIcon = styled(FaTh)`
  font-size: 14px;
  
   @media(max-width: 1050px) {
    font-size: 18px;
  }
`;

export const Icon = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center; // Adicionado para centralizar o ícone
  color: ${({ selected }) => (selected ? '#fff' : '#000')}; // Cor do ícone

  @media(max-width: 1050px) {
    margin-right: 0px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-around;

  // @media(max-width: 600px) {
  //   margin-bottom: 20px;
  // }
`;

export const NavButton = styled.button`
  background: ${({ selected }) => (selected ? '#808080' : 'white')}; // Cinza mais escuro
  border: 1px solid ${({ selected }) => (selected ? '#808080' : '#ccc')}; // Cinza mais escuro
  box-shadow: ${({ selected }) => (selected ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)')}; // Sombra mais escura
  font-size: 16px;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 50px;
  margin: -10px 7px;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s, color 0.3s;
  color: ${({ selected }) => (selected ? '#fff' : '#000')}; 
  h1 {
    font-size: 16px;
  }
  &:hover {
    background-color:#808080;
    color: #fff;
    ${Icon} {
      color: #fff;
    }
  }
    @media(max-width: 1050px) {
    h1 {
      display: none;
    }
  }
`;
import React, { useContext, useState } from 'react';
import { NavContainer, NavButton, Icon, FaThIcon } from './styled';
import { FaClockIcon, FaStarIcon, FaThLargeIcon } from './styled';
import { updateLastReportFilter } from 'services/api/users';
import { Context } from 'contexts/context';

const CardsFilters = ({ onNavClick, thisUser }) => {
  const [selected, setSelected] = useState(thisUser.last_report_filter || 'recentes')
  const { firm } = useContext(Context)

  const handleNavClick = (type) => {
    setSelected(type);
    onNavClick(type);
    updateLastReportFilter(thisUser.id, {last_report_filter: type});
  };

  return (
    <NavContainer>
      <NavButton selected={selected === 'recentes'} onClick={() => handleNavClick('recentes')}>
        <Icon selected={selected === 'recentes'}><FaClockIcon /></Icon>
        <h1>Recentes</h1>
      </NavButton>
      <NavButton selected={selected === 'favoritos'} onClick={() => handleNavClick('favoritos')}>
        <Icon selected={selected === 'favoritos'}><FaStarIcon /></Icon>
        <h1>Favoritos</h1>
      </NavButton>
      <NavButton selected={selected === 'meusAplicativos'} onClick={() => handleNavClick('meusAplicativos')}>
        <Icon selected={selected === 'meusAplicativos'}><FaThLargeIcon /></Icon>
        <h1>Meus Relatórios</h1>
      </NavButton>
      {firm.cards_catalog === 1 && (
       <NavButton selected={selected === 'todosCards'} onClick={() => handleNavClick('todosCards')}>
         <Icon selected={selected === 'todosCards'}><FaThIcon /></Icon>
         <h1>Catálogo de Relatórios</h1>
       </NavButton>
     )}
    </NavContainer>
  );
};

export default CardsFilters;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CardLink, FavoritesTippy, InfoIcon, InfoIconWrapper, ModalContainer, StarIcon, StarIconWrapper, Tippys, TooltipWrapper } from './style';
import 'tippy.js/dist/tippy.css';
import { handleUpdateFavoriteReport } from 'services/api/reports';
import { Context } from 'contexts/context';
import CardInfo from 'components/CardInfo';

const Component = ({ name, fileUrl, linkTo, reportId, reportCardId, isfavorite, updateFavorites, data_source, report_description, responsible_id, onInfoClick, carousel }) => {

  const { favorites, setFavorites } = useContext(Context);
  const [isFavorite, setIsFavorite] = useState(favorites[reportCardId] !== undefined ? favorites[reportCardId] : isfavorite);
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    setIsFavorite(favorites[reportCardId] !== undefined ? favorites[reportCardId] : isfavorite);
  }, [favorites, reportCardId, isfavorite]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalInfoOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    const handleKeyDown = (event) => {
      if (modalInfoOpen && event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalInfoOpen]);


  const handleToggleFavorite = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newFavoriteStatus = !isFavorite;
    
    // Atualiza o estado local imediatamente
    setIsFavorite(newFavoriteStatus);

    // Aguarda a atualização no banco de dados
    await handleUpdateFavoriteReport(reportId, newFavoriteStatus, reportCardId);
    
    // Atualiza o estado global/contexto
    setFavorites((prevFavorites) => {
      const newFavorites = { ...prevFavorites };
      newFavorites[reportCardId] = newFavoriteStatus;
      return newFavorites;
    });

    // Chama a função de atualização dos favoritos
    updateFavorites();
  };

  const handleInfo = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setModalInfoOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalInfoOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <CardLink to={linkTo}>
        {(report_description || data_source) && (
          <InfoIconWrapper onClick={carousel ? onInfoClick : handleInfo}>
            <Tippys content="Informações do relatório" placement="top" arrow={true}>
              <TooltipWrapper>
                <InfoIcon />
              </TooltipWrapper>
            </Tippys>
          </InfoIconWrapper>
        )}
        <StarIconWrapper onClick={handleToggleFavorite}>
          <Tippys content={isFavorite ? "Remover dos favoritos" : "Adicionar aos favoritos"} placement="top" arrow={true}>
            <TooltipWrapper>
              <StarIcon isfavorite={isFavorite} />
            </TooltipWrapper>
          </Tippys>
        </StarIconWrapper>
        <img src={fileUrl ? `${process.env.REACT_APP_URL_API}/${fileUrl}` : '/standardCard.png'} alt="analyticdi" />
        <p style={{ textAlign: 'center' }} data-content={name}>{name}</p>
      </CardLink>

      {modalInfoOpen && (
         <div>
         <div style={{ 
             position: 'fixed', 
             top: 0, 
             left: 0, 
             right: 0, 
             bottom: 0, 
             backgroundColor: 'rgba(0, 0, 0, 0.5)',
             zIndex: 999
         }} onClick={closeModal} />
         <div ref={modalRef} style={{ zIndex: 1000 }}>
           <CardInfo 
            closeModal={closeModal}
            name={name}
            fileUrl={fileUrl}
            reportId={reportId}
            reportCardId={reportCardId}
            data_source={data_source}
            report_description={report_description}
            responsible_id={responsible_id}
           />
         </div>
       </div>
      )}
    </>
  );
};

export default Component;
import { useContext, useEffect, useRef, useState } from "react";
import { Notification, NotificationMenu, NotificationTitle } from "./styled"
import { FaTimes, FaWhatsapp } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { Context } from "contexts/context";

const Notifications = (props) => {

    const { showNotifications, setShowNotifications, firm, user } = useContext(Context)
    const [website, setWebsite] = useState('')
    const notificationRef = useRef(null);
    const bellIconRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    
    useEffect(() => {
        const loadData = async () => {
            setIsLoading(false);
        };
        loadData();
    }, [showNotifications]);

    useEffect(()=> {
        // if (firm.id === 19) setWebsite('moinhoregio.datotecnologia.com.br')
        if (firm.id === 11 && user.company_id === 2) setWebsite('cromotransfer.datotecnologia.com.br') 
            // TO CHANGE
        }, [firm, user])

    const closeNotification = (event) => {
        event.stopPropagation();
        setShowNotifications(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Verifica se o clique foi fora do menu de notificações e do ícone do sino
            if (notificationRef.current && 
                !notificationRef.current.contains(event.target) && 
                bellIconRef.current && 
                !bellIconRef.current.contains(event.target)) {
                setShowNotifications(false); // Fecha as notificações
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // useEffect(() => {
    //     if (isClickingBell) {
    //         setIsClickingBell(false); // Reseta o estado após o clique
    //     }
    // }, [showNotifications]);

    const today = new Date();
    const startDate = new Date('2024-12-19'); // Data de início
    const diffTime = startDate - today; // Diferença em milissegundos
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Diferença em dias


    return (
        <div>
            {isLoading ? ( // Verifica se está carregando
                <div></div> // Exibe mensagem de carregamento
            ) : (
                <>
                    {showNotifications && ( // Render notification menu if showNotifications is true
                        <NotificationMenu ref={notificationRef}>
                            <NotificationTitle>
                            <div style={{fontWeight: 'bold'}}>
                                Notificações
                            </div>
                            <div ref={bellIconRef}>
                                <AiOutlineClose
                                 onClick={closeNotification}
                                 style={{cursor: "pointer"}}/>
                            </div>
                            </NotificationTitle>

                            <Notification>
                            {diffDays === 0 ? null : 'Em'} <span style={{color: "red", fontWeight: "bold"}}>{diffDays === 0 ? 'Amanhã' : `${diffDays} dias`}</span>, o acesso à plataforma mudará para
                                <span style={{fontWeight: "bold"}}> {website}</span>.<br/>
                                    <div style={{height: '8px'}}/>
                                Após esse período, o acesso pelo site app.datotecnologia não será mais possível.<br/>
                                    <div style={{height: '8px'}}/>
                                Qualquer dúvida, estamos à disposição. Obrigado!
                                    <div style={{height: '8px'}}/>
                                <a href="https://api.whatsapp.com/send?phone=5547936189825&text=Ol%C3%A1%21%20Estou%20com%20d%C3%BAvidas%20referente%20a%20troca%20de%20Dom%C3%ADnio%20na%20Dato%20Hub.%20Poderia%20me%20explicar%20melhor%3F" 
                                    target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                    <button 
                                        style={{ backgroundColor: 'rgb(23, 59, 107)', 
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '5px 8px',
                                            borderRadius: '20px',
                                            // border: '1px solid rgb(23, 59, 107)'
                                            }}>
                                        <FaWhatsapp style={{ marginRight: '0.5rem' }} size={20}/> {/* Add WhatsApp icon */}
                                        Estou com dúvidas
                                    </button>
                                </a>
                            </Notification>
                            
                        </NotificationMenu>
                    )}
                </>
            )}
        </div>
    )
}

export default Notifications
import { handleUserShow } from 'services/api/users'
import { CardInfoStyle, CloseCardInfo, InfoContainer } from './styled'
import { FaWindowClose } from 'react-icons/fa'
import { useEffect, useState } from 'react'

const CardInfo = props => {

    const [responsibleName, setResponsibleName] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        if (props.responsible_id !== null) {
            const user = await handleUserShow(props.responsible_id)
            setResponsibleName(user.name)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <CardInfoStyle style={{ overflow: 'auto' }}>
            {loading ? (
                <></>
            ) : (
                <>
                    <CloseCardInfo onClick={props.closeModal}>
                        <div style={{
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <FaWindowClose size={20} color='red'/>
                        </div>
                    </CloseCardInfo>
                    <h1>Relatório: {props.name}</h1>
                    <InfoContainer>
                        {props.data_source && (
                            <p style={{ margin: '0' }}>Fonte de Dados:
                                <span style={{ marginLeft: '3px' }}> {props.data_source}</span>
                            </p>
                        )}
                        {props.report_description && (
                            <p style={{ margin: '0' }}>Descrição: 
                                <span style={{ marginLeft: '3px' }}> {props.report_description}</span>
                            </p>
                        )}
                        {responsibleName && (
                            <p style={{ margin: '0' }}>Responsável: 
                                <span style={{ marginLeft: '3px' }}> {responsibleName}</span>
                            </p>
                        )}
                    </InfoContainer>
                </>
            )}
        </CardInfoStyle>
    )
}

export default CardInfo
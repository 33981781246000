import { useEffect, useState, useContext} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaChartArea } from "react-icons/fa"
import { handlePowerBiReports } from 'services/api/powerbi'
import { handleFavoritesReportsByUser, handleRecentsReportsByUser, handleReportsByUserWithCard, reportsByUserAndReportCardFirm } from 'services/api/reports'
import { Context } from 'contexts/context'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
// import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import Container from 'components/Container'
import ReportCard from 'components/ReportCard'
import api from 'services/api/api'
import InputWithSearchIcon from 'components/InputSearch'
import CardsFilters from 'components/CardsFilters'
import { CotainerReportCardCarousel, DepartmentContainer, DepartmentsContainer, GreyTippy, IconCards, InputCadastrar, InputIconsDiv, SelectDepartmentContainer, VerticalLine } from 'pages/Cards/styled'
import { FaAngleDown, FaAngleRight, FaAngleLeft  } from 'react-icons/fa'
import 'tippy.js/dist/tippy.css';
import { updateCardsOpen } from 'services/api/users'
import { getUser } from 'services/auth'
import { CardTitle, CardTitlep } from 'components/CardTitle/styled'
import { ReportCardCarousel } from 'components/CardsCarousel'
import UnauthorizedCardComponent from 'components/UnauthorizedCard'

const Page = () => {
  
  const [dataReports, setDataReports] = useState([])
  const [dataReportCards, setDataReportCards] = useState([])
  const [data, setData] = useState([])
  const [filter, setFilter] = useState('');
  const [expandedContainers, setExpandedContainers] = useState({});
  const { user, setPainelTV, firm } = useContext(Context)
  const [thisUser, setThisUser] = useState(null)
  const [expandedSelect, setExpandedSelect] = useState(null)
  const [recentsReports, setRecentsReports] = useState([])
  const [favoritesReports, setFavoritesReports] = useState([])
  const [activeTab, setActiveTab] = useState('');

  const params = useParams()

  const updateFavorites = async () => {
    const userData = await getUser();
    const updatedFavoritesReports = await handleFavoritesReportsByUser(userData.id);
    setFavoritesReports(updatedFavoritesReports);
  };

  useEffect(() => {
    (async () => {
      const userData = await getUser()

      try {
        if (userData.roles === 'admin') {
          setData(await handlePowerBiReports(params.group_id))
        } else {
          setActiveTab(userData.last_report_filter || 'recentes')
          // setData(await handleReportsByUserWithCard())
          const reportsAndReportsCards = await reportsByUserAndReportCardFirm()
          
          setDataReports(reportsAndReportsCards.reports)
          setDataReportCards(reportsAndReportsCards.reportCards)

          // Recents
          const recentsReports = await handleRecentsReportsByUser(userData.id)
          setRecentsReports(recentsReports)
         
          // Favorites
          const favoritesReports = await handleFavoritesReportsByUser(userData.id)
          setFavoritesReports(favoritesReports)

          const { data } = await api.get(`/carousels/by-user`)
          if (!data) setPainelTV(false)

          // const dataCards = await handleReportsByUserWithCard()
          if (userData.cardsClosed) firstMinimizeAllGroups(reportsAndReportsCards.reports, reportsAndReportsCards.reportCards)
          else firstExpandAllGroups(reportsAndReportsCards.reports, reportsAndReportsCards.reportCards)
          
          setThisUser(userData)
          if(thisUser) setExpandedSelect(userData.cardsClosed)
        }
      } catch(error) {
        console.log(error)
      }
    })()
  }, [params.group_id, user.roles])

  function includesFilter(value, filter) {
    return value && value.toLowerCase().includes(filter.toLowerCase());
}

  const filteredData = data
    .filter(item =>
      includesFilter(item.name, filter) ||
      includesFilter(item.department_description, filter)
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  // REPORTS
  const filteredReports = dataReports
  .filter(item =>
    includesFilter(item.name, filter) ||
    includesFilter(item.department_description, filter)
  )
  .sort((a, b) => a.name.localeCompare(b.name));

  const groupedDataReports = filteredReports.reduce((groups, item) => {
    const departmentId = item.department_description || 'null';
    if (!groups[departmentId]) {
      groups[departmentId] = [];
    }
    groups[departmentId].push(item);
    return groups;
  }, {});

  Object.keys(groupedDataReports).forEach(departmentId => {
    groupedDataReports[departmentId].sort((a, b) => a.name.localeCompare(b.name));
  });

  const departmentIds = Object.keys(groupedDataReports);
  const sortedDepartments = departmentIds.filter(departmentId => departmentId !== "null").sort();
  const otherDepartment = departmentIds.find(departmentId => departmentId === "null");

  const sortedDepartmentIds = otherDepartment ? sortedDepartments.concat(otherDepartment) : sortedDepartments;
  const orderedDepartmentIds = sortedDepartmentIds.sort();

  // REPORT CARDS
  const filteredReportCards = dataReportCards
  .filter(item =>
    includesFilter(item.name, filter) ||
    includesFilter(item.department_description, filter)
  )
  .sort((a, b) => a.name.localeCompare(b.name));

  const groupedDataReportCards = filteredReportCards.reduce((groups, item) => {
    const departmentId = item.department_description || 'null';
    if (!groups[departmentId]) {
      groups[departmentId] = [];
    }
    groups[departmentId].push(item);
    return groups;
  }, {});

  Object.keys(groupedDataReportCards).forEach(departmentId => {
    groupedDataReportCards[departmentId].sort((a, b) => a.name.localeCompare(b.name));
  });
  
  const departmentIdsFirm = Object.keys(groupedDataReportCards);
  const allDepartmentIds = [...new Set(departmentIdsFirm.concat(departmentIds))];
  
  const allSortedDepartmentsFirm = allDepartmentIds.filter(departmentId => departmentId !== "null").sort();
  const allOtherDepartmentFirm = allDepartmentIds.find(departmentId => departmentId === "null");

  const allSortedDepartmentIdsFirm = allOtherDepartmentFirm ? allSortedDepartmentsFirm.concat(allOtherDepartmentFirm) : allSortedDepartmentsFirm;
  const orderedDepartmentIdsFirm = allSortedDepartmentIdsFirm.sort();
  
    // const reportCardsIds = Object.values(groupedDataReportCards).flat().map(item => item.report_card_id);

    const toggleExpansion = departmentId => {
      setExpandedContainers(prevState => ({
        ...prevState,
        [departmentId]: !prevState[departmentId]
      }));
    };
    
    async function expandAllGroups(reportCardsData, reportCardsDataFirm){
      if(!expandedSelect) {
        setExpandedSelect(true)
        const initialExpandedContainers = {};
        reportCardsData.forEach(item => {
          initialExpandedContainers[item.department_description] = true;
        });
        reportCardsDataFirm.forEach(item => {
          initialExpandedContainers[item.department_description] = true;
        });
        setExpandedContainers(initialExpandedContainers)
        await updateCardsOpen(thisUser.id, {cardsClosed: 0})
      }
    }

    async function minimizeAllGroups(reportCardsData, reportCardsDataFirm){
      if(expandedSelect) {
        setExpandedSelect(false)
        const initialExpandedContainers = {};
        reportCardsData.forEach(item => {
          initialExpandedContainers[item.department_description] = false;
        });
        reportCardsDataFirm.forEach(item => {
          initialExpandedContainers[item.department_description] = false;
        });
        setExpandedContainers(initialExpandedContainers);
        await updateCardsOpen(thisUser.id, {cardsClosed: 1})
      }
    }

    function firstExpandAllGroups(reportCardsData, reportCardsDataFirm) {
      setExpandedSelect(true);
      const initialExpandedContainers = {};
      
      reportCardsData.forEach(item => {
        initialExpandedContainers[item.department_description] = true;
      });
      
      reportCardsDataFirm.forEach(item => {
        if (!initialExpandedContainers[item.department_description]) {
          initialExpandedContainers[item.department_description] = true;
        }
      });
      
      setExpandedContainers(initialExpandedContainers);
    }
    function firstMinimizeAllGroups(reportCardsData, reportCardsDataFirm){
        setExpandedSelect(false)
        const initialExpandedContainers = {};
        reportCardsData.forEach(item => {
          initialExpandedContainers[item.department_description] = false;
        });
        reportCardsDataFirm.forEach(item => {
          if (!initialExpandedContainers[item.department_description]) {
            initialExpandedContainers[item.department_description] = false;
          }
        });
        setExpandedContainers(initialExpandedContainers);
    }

    const handleNavClick = (tab) => {
      setActiveTab(tab);
      // Add logic to fetch data based on the active tab
    };

  return (
    <>
      <TitleBar label="Relatórios" currentPage=""/>

      <Content>
        <Card>
        {user.roles === 'admin' ?
        <>
          <CardTitle title="Meus Relatórios">
          <div style={{margin: '23px'}}></div>
            <InputCadastrar>
            
              <InputWithSearchIcon 
                type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
              </InputCadastrar>
          </CardTitle>

            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th width="50">Ações</th>
                </tr>
              </thead>

              <tbody>
                {filteredData.map(item =>
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    
                    <td>
                      <SmLinkBlue to={`/grupos/${params.group_id}/relatorios/${item.id}/datasets/${item.datasetId}`}>
                        <FaChartArea />
                      </SmLinkBlue>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            </> :
            ( typeof thisUser?.cardsClosed !== 'undefined' && (
            <>
            
              <CardTitle>

                <CardTitlep>
                  <CardsFilters 
                    onNavClick={handleNavClick} 
                    thisUser={thisUser}/> 
                </CardTitlep>

                <InputIconsDiv>
                  <InputCadastrar>
                    <InputWithSearchIcon 
                  type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
                  <VerticalLine/>
                  <span>
                    <GreyTippy
                      content="Exibição grupos fechados">
                    <IconCards
                      onClick={() => minimizeAllGroups(dataReports, dataReportCards)}
                      src="/icon fechado.png" 
                      alt="Close Groups Icon" 
                      op={!expandedSelect}/>
                  </GreyTippy>
                  </span>
                  <span>
                    <GreyTippy
                      content="Exibição grupos abertos">
                    <IconCards
                      onClick={() => expandAllGroups(dataReports, dataReportCards)}
                      src="/icon - aberto.png" 
                      alt="Open Groups Icon" 
                      op={expandedSelect}/>
                  </GreyTippy>
                  </span>
                </InputCadastrar>
              </InputIconsDiv>
            </CardTitle>
            <Container>
            {activeTab === 'recentes'  && recentsReports.length > 0 && (
                <CotainerReportCardCarousel>
                  <ReportCardCarousel 
                    reportsCarousel={recentsReports}
                    updateFavorites={updateFavorites} />
                </CotainerReportCardCarousel>
              )}
              {activeTab === 'favoritos'  && favoritesReports.length > 0 && (
                <CotainerReportCardCarousel>
                  <ReportCardCarousel 
                  reportsCarousel={favoritesReports}
                  updateFavorites={updateFavorites} />
                </CotainerReportCardCarousel>
              )}
              {activeTab === 'meusAplicativos' && (
                <></>
              )}
              {/* Aqui adicionar componente de favoritos */}
              {(activeTab === 'todosCards' && firm.cards_catalog === 1) ? orderedDepartmentIdsFirm.map(departmentId => (
                <DepartmentsContainer key={departmentId}>
                  {departmentId !== "null" ? (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>{departmentId}</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                  </SelectDepartmentContainer>
                  ) : (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>Outros</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                    </SelectDepartmentContainer>
                  )}
                  <DepartmentContainer style={{ maxHeight: expandedContainers[departmentId] ? 'none' : '0px', overflow: 'hidden' }}>
                    {groupedDataReports[departmentId] && groupedDataReports[departmentId].map(item => (
                      <ReportCard 
                        key={item.id} 
                        name={item.name} 
                        fileUrl={item.file_url} 
                        linkTo={`/grupos/${item.group_id}/relatorios/${item.report_id}/datasets/${item.dataset_id}`}
                        reportId={item.id}
                        reportCardId={item.report_card_id}
                        isfavorite={item.favorite === 1 ? true : false}
                        updateFavorites={updateFavorites}
                        data_source={item.data_source}
                        report_description={item.report_description}
                        responsible_id={item.responsible_id}
                      />
                    ))}
                    {groupedDataReportCards[departmentId] && groupedDataReportCards[departmentId].map(item => (
                      <UnauthorizedCardComponent 
                        key={item.id} 
                        name={item.name} 
                        fileUrl={item.file_url} 
                        reportCardId={item.report_card_id}
                        data_source={item.data_source}
                        report_description={item.report_description}
                        responsible_id={item.responsible_id}
                      />
                    ))}
                  </DepartmentContainer>
                </DepartmentsContainer>
              )) : orderedDepartmentIds.map(departmentId => (
                <DepartmentsContainer key={departmentId}>
                  {departmentId !== "null" ? (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>{departmentId}</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                  </SelectDepartmentContainer>
                  ) : (
                    <SelectDepartmentContainer onClick={() => toggleExpansion(departmentId)}>
                      <section>Outros</section>
                      <button>
                        {expandedContainers[departmentId] ? 
                          <span><FaAngleDown size={26} /></span> : 
                          <span><FaAngleRight size={26} /></span>}
                      </button>
                    </SelectDepartmentContainer>
                  )}
                  <DepartmentContainer style={{ maxHeight: expandedContainers[departmentId] ? 'none' : '0px', overflow: 'hidden' }}>
                    {groupedDataReports[departmentId] && groupedDataReports[departmentId].map(item => (
                      <ReportCard 
                        key={item.id} 
                        name={item.name} 
                        fileUrl={item.file_url} 
                        linkTo={`/grupos/${item.group_id}/relatorios/${item.report_id}/datasets/${item.dataset_id}`}
                        reportId={item.id}
                        reportCardId={item.report_card_id}
                        isfavorite={item.favorite === 1 ? true : false}
                        updateFavorites={updateFavorites}
                        responsible_id={item.responsible_id}
                        data_source={item.data_source}
                        report_description={item.report_description}
                      />
                    ))}
                  </DepartmentContainer>
                </DepartmentsContainer>
              ))}
            
            </Container>
            </>
            )
          )}
        </Card>
      </Content>
    </>
  )
}

export default Page
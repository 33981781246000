import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'

import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react"
import { BiBellFill } from 'react-icons/bi'
import { MdNotifications } from 'react-icons/md'

export const Topbar = styled.header`
    height: 60px;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    z-index: 1;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);

    @media (max-width: 900px) {
        padding: 0 1rem;
    } 
`

export const Logo = styled.img`
    height: 40px;

    @media (max-width: 350px) {
        width: 120px;
    } 
`

export const NavigationContainer = styled.div`
    margin-left: 15px;
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
        display: none;
    } 
`

export const ShowMobile = styled.div`
    display: none;
    align-items: center;
    margin-left: 1rem;

    @media (max-width: 900px) {
        display: block;
    } 
`

export const NavigationLink = styled(Link)`
    margin-left: 1rem;
    text-decoration: none;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    position: relative;
    
    &:hover {
        opacity: .5;
    }
`

export const NavigationText = styled.span`
    // margin-left: .5rem;
    text-decoration: none !important;
    color: var(--color-orange);
    text-align: center;

    @media (max-width: 900px) {
        display: ${props => props.show ? "block" : "none"};
    } 
`

export const LeftSideContainer = styled.div`
    display: flex;
    align-items: center;
`

export const RightSideContainer = styled.div`
    display: flex;
    align-items: center;
`

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
`

export const Divider = styled.div`
    height: 25px;
    width: .5px;
    margin: 0 1rem;
    border-radius: 100%;
    background-color: var(--color-orange);

    @media (max-width: 900px) {
        margin: 0 1rem;
    } 

    @media (max-width: 350px) {
        margin: 0 .5rem;
    } 
`

export const Dropdown = styled(CDropdown)``

export const DropdownToggle = styled(CDropdownToggle)`
    background-color: transparent !important;
    border-radius: 30px;
    border: none;
    transition: all 0.3s;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    font-weight: bold;

    &:hover {
        opacity: 0.5;
    }

    &:focus {
        outline: 0;
    }

    @media (max-width: 900px) {
        font-size: 15px !important;
    } 

    &.dropdown-toggle::after {
        color: var(--color-orange);
    }
`

export const DropdownMenu = styled(CDropdownMenu)`
    margin-top: 8px;
    transform: translate(0px, 52px) !important;

    @media (max-width: 900px) {
        transform: translate(0px, 52px) !important;
    }
`

export const DropdownItem = styled(CDropdownItem)`
    background-color: white !important;
    cursor: pointer;

    &:hover {
        opacity: .5;
    }
`

export const DropdownText = styled.span`
    margin-left: .5rem;
    color: var(--color-orange);
`

export const UserContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
`

export const UserName = styled.h6`
    display: flex;
    align-items: center;
    height: 2rem;
    padding-right: .5rem;
    font-weight: 600;
    color: var(--color-orange);

    @media (max-width: 900px) {
        display: none;
    } 
`

export const UserPhoto = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: .5rem;
`

export const Dividers = styled.hr`
  border: 0;
  height: 2.2px;
  background-color: var(--color-orange);
  opacity: 1;
  margin: 0;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
`;

export const BellIcon = styled(MdNotifications)`
    font-size: 20px;
    cursor: pointer;
    color: var(--color-orange);
    
    &:hover {
        opacity: .5;
    }

    ${({ disabled }) => disabled ? `
        opacity: 0.5; /* Apenas altera a opacidade quando desabilitado */
        pointer-events: none; /* Desabilita a interação do mouse */
        cursor: default; /* Muda o cursor para o padrão */
    ` : `
        cursor: pointer; /* Garante que o cursor seja um ponteiro quando habilitado */
    `}
`
